import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Breadcrumbs = ({ isFrench }) => {
  const location = useLocation();
  
  // Skip rendering breadcrumbs on homepage
  if (location.pathname === '/' || location.pathname === '/fr') {
    return null;
  }
  
  // Get the path without the language prefix for processing
  const pathWithoutLang = isFrench ? location.pathname.replace('/fr', '') : location.pathname;
  
  // Parse the current path into breadcrumb segments
  const pathSegments = pathWithoutLang.split('/').filter(segment => segment);
  
  // Translate path segments for French
  const translatePathSegment = (segment) => {
    if (!isFrench) return segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, ' ');
    
    // French translations for path segments
    const translations = {
      'manufacturers': 'Fabricants',
      'products': 'Produits',
      'contact': 'Contact'
    };
    
    return translations[segment] || segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, ' ');
  };
  
  // Generate breadcrumb items
  const breadcrumbItems = [
    { name: isFrench ? 'Accueil' : 'Home', path: isFrench ? '/fr' : '/' },
    ...pathSegments.map((segment, index) => {
      let path;
      if (isFrench) {
        path = `/fr/${pathSegments.slice(0, index + 1).join('/')}`;
      } else {
        path = `/${pathSegments.slice(0, index + 1).join('/')}`;
      }
      return {
        name: translatePathSegment(segment),
        path
      };
    })
  ];
  
  // Base URL for structured data
  const baseUrl = 'https://northtouch.com';
  
  // Create JSON-LD structured data for breadcrumbs
  const breadcrumbsStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadcrumbItems.map((item, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'name': item.name,
      'item': `${baseUrl}${item.path}`
    }))
  };
  
  return (
    <>
      {/* Add structured data for breadcrumbs */}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsStructuredData)}
        </script>
      </Helmet>
      
      {/* Visual breadcrumbs */}
      <nav aria-label="Breadcrumb" className="py-3 px-6 bg-gray-50">
        <ol className="flex flex-wrap text-sm">
          {breadcrumbItems.map((item, index) => {
            const isLast = index === breadcrumbItems.length - 1;
            
            return (
              <li key={item.path} className="flex items-center">
                {index > 0 && (
                  <svg className="mx-2 h-4 w-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                )}
                
                {isLast ? (
                  <span className="text-gray-700 font-medium" aria-current="page">
                    {item.name}
                  </span>
                ) : (
                  <Link to={item.path} className="text-primary-600 hover:text-primary-800 hover:underline">
                    {item.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumbs; 