import React from "react";
import { motion } from "framer-motion";

const ManufacturerCard = ({
  logo,
  name,
  description,
  website,
  category,
  index,
  isFrench,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="relative overflow-hidden rounded-2xl bg-white group h-full w-full transition-all duration-300 shadow-lg hover:shadow-2xl border border-gray-100 flex flex-col"
    >
      {/* Background pattern and gradient */}
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="absolute bottom-0 left-0 right-0 h-2/3 bg-gradient-to-t from-purple-50 to-transparent"></div>

      {/* Glass effect elements */}
      <div className="absolute top-6 right-6 w-32 h-32 bg-purple-600/20 rounded-full blur-3xl opacity-0 group-hover:opacity-40 transition-opacity duration-700"></div>
      <div className="absolute bottom-12 left-6 w-24 h-24 bg-indigo-400/20 rounded-full blur-3xl opacity-0 group-hover:opacity-50 transition-opacity duration-700"></div>

      {/* Content */}
      <div className="relative p-6 flex flex-col h-full z-10">
        <div className="flex justify-between items-start mb-5">
          <div className="bg-white p-4 rounded-xl border border-gray-200 shadow-md w-40 h-24 flex items-center justify-center group-hover:shadow-lg transition-all duration-300">
            <img
              src={logo}
              alt={name}
              className="max-h-16 max-w-full object-contain"
            />
          </div>
          <span className="inline-flex items-center justify-center py-1 px-2.5 rounded-full text-[10px] md:text-xs font-semibold bg-gradient-to-r from-purple-600 to-purple-800 text-white shadow-sm">
            {category}
          </span>
        </div>

        <h3 className="text-xl font-bold text-gray-900 mb-3 line-clamp-2">
          {name}
        </h3>
        <p className="text-sm text-gray-600 flex-grow mb-5 line-clamp-3">
          {description}
        </p>

        <a
          href={website}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-4 py-2 rounded-lg font-medium shadow-md hover:shadow-lg transition-all duration-300 transform group-hover:-translate-y-1 w-fit mt-auto text-sm"
        >
          {isFrench ? "Explorer les Solutions" : "Explore Solutions"}
          <svg
            className="w-3 h-3 ml-2 transform transition-transform duration-300 group-hover:translate-x-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </a>
      </div>
    </motion.div>
  );
};

export default ManufacturerCard;
