import React from "react";
import { motion } from "framer-motion";
import ServiceCard from "../components/ServiceCard";

const ServicesSection = ({ isFrench }) => {
  return (
    <section className="py-16 md:py-24 bg-white relative overflow-hidden">
      <div className="container mx-auto px-6 relative z-10">
        <motion.div
          className="text-center max-w-3xl mx-auto mb-12 md:mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <div className="inline-block mb-2">
            <span className="text-xs uppercase tracking-wider text-primary-700 font-semibold bg-primary-50 py-1 px-3 rounded-full">
              {isFrench ? "Ce Que Nous Faisons" : "What We Do"}
            </span>
          </div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            {isFrench ? "Nos Services" : "Our Services"}
          </h2>
          <p className="text-gray-600 text-lg">
            {isFrench
              ? "Nous fournissons des solutions complètes pour la fabrication électronique, servant le marché canadien avec expertise et dévouement."
              : "We provide comprehensive solutions for electronics manufacturing, serving the Canadian market with expertise and dedication."}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          <ServiceCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                />
              </svg>
            }
            title={
              isFrench
                ? "Solutions de Test Avancées"
                : "Advanced Testing Solutions"
            }
            description={
              isFrench
                ? "Nous fournissons des équipements et des solutions de test de pointe pour assurer la qualité et la fiabilité des produits à chaque étape du processus de fabrication."
                : "We provide cutting-edge testing equipment and solutions to ensure product quality and reliability at every stage of the manufacturing process."
            }
            delay={0.1}
          />
          <ServiceCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            }
            title={isFrench ? "Équipement d'Assemblage" : "Assembly Equipment"}
            description={
              isFrench
                ? "Machines et équipements d'assemblage à la pointe de la technologie pour des opérations de fabrication électronique efficaces et précises."
                : "State-of-the-art assembly machines and equipment for efficient and precise electronics manufacturing operations."
            }
            delay={0.2}
          />
          <ServiceCard
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                />
              </svg>
            }
            title={
              isFrench
                ? "Vérification et Diagnostic"
                : "Verification & Diagnostic"
            }
            description={
              isFrench
                ? "Systèmes complets de vérification et de diagnostic pour identifier et résoudre rapidement les problèmes, minimisant les temps d'arrêt et maximisant la productivité."
                : "Complete verification and diagnostic systems to identify and resolve issues quickly, minimizing downtime and maximizing productivity."
            }
            delay={0.3}
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
