import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/images/northtouch-site-logo.png";

const MobileNav = ({
  isFrench,
  getPathPrefix,
  toggleMenu,
  isMenuOpen,
  switchLanguage,
}) => {
  return (
    <div className="w-full flex justify-between items-center">
      <Link to={isFrench ? "/fr" : "/"} className="flex items-center">
        <img src={logo} alt="Northtouch Canada Inc." className="h-10 md:h-12" />
      </Link>

      {/* Mobile Menu Button - Improved visibility and tap area */}
      <button
        onClick={toggleMenu}
        className="text-primary-700 p-2 rounded-full hover:bg-primary-50 transition-colors focus:outline-none shadow-sm z-50 relative"
        aria-label="Toggle menu"
      >
        {isMenuOpen ? (
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </button>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <motion.div
          className="fixed inset-0 bg-white z-40 overflow-y-auto"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="flex flex-col items-center relative py-16 px-6 w-full">
            <img
              src={logo}
              alt="Northtouch Canada Inc."
              className="h-16 mb-12"
            />

            <nav className="w-full max-w-md">
              <ul className="space-y-6 text-center">
                <li>
                  <Link
                    to={getPathPrefix()}
                    className="text-2xl font-medium text-gray-800 hover:text-primary-600 transition-colors"
                    onClick={toggleMenu}
                  >
                    {isFrench ? "Accueil" : "Home"}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${getPathPrefix()}/manufacturers`}
                    className="text-2xl font-medium text-gray-800 hover:text-primary-600 transition-colors"
                    onClick={toggleMenu}
                  >
                    {isFrench ? "Fabricants" : "Manufacturers"}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${getPathPrefix()}/products`}
                    className="text-2xl font-medium text-gray-800 hover:text-primary-600 transition-colors"
                    onClick={toggleMenu}
                  >
                    {isFrench ? "Produits" : "Products"}
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${getPathPrefix()}/contact`}
                    className="text-2xl font-medium text-gray-800 hover:text-primary-600 transition-colors"
                    onClick={toggleMenu}
                  >
                    {isFrench ? "Contact" : "Contact"}
                  </Link>
                </li>
                <li className="pt-6">
                  <button
                    onClick={() => {
                      switchLanguage();
                      toggleMenu();
                    }}
                    className="inline-flex items-center text-lg font-medium text-primary-600 hover:text-primary-700 transition-colors"
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129"
                      />
                    </svg>
                    {isFrench ? "English" : "Français"}
                  </button>
                </li>
              </ul>
            </nav>

            <div className="mt-12 flex flex-col items-center space-y-4">
              <a
                href="tel:5148259611"
                className="inline-flex items-center text-gray-600 hover:text-primary-600 transition-colors"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                514.825.9611
              </a>
              <a
                href="mailto:sales@northtouch.com"
                className="inline-flex items-center text-gray-600 hover:text-primary-600 transition-colors"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                sales@northtouch.com
              </a>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default MobileNav;
