import React from "react";
import { motion } from "framer-motion";

const ContactMapSection = () => {
  return (
    <section className="py-12 md:py-16 bg-white relative">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="container mx-auto px-6 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8 text-center">
            Visit Us
          </h2>
          <div className="h-[400px] rounded-xl overflow-hidden shadow-lg border border-gray-200">
            <iframe
              title="Northtouch Canada Inc. Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2801.272516567094!2d-73.8518345!3d45.432697700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93f58eadf0a2b%3A0x765a22e0febe44bb!2s3551%20Boul%20St-Charles%20Suite%20511%2C%20Kirkland%2C%20Quebec%20H9H%203C4%2C%20Canada!5e0!3m2!1sen!2sus!4v1710439952049!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactMapSection;
