import React from "react";
import { motion } from "framer-motion";

const ManufacturersHeroSection = ({ isFrench, manufacturersCount }) => {
  return (
    <section className="pt-32 md:pt-40 pb-24 relative overflow-hidden">
      {/* Background elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-black z-0"></div>

      {/* Enhanced blur effects */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-500 rounded-full filter blur-[120px] opacity-20 mix-blend-screen"></div>
      <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-indigo-500 rounded-full filter blur-[120px] opacity-20 mix-blend-screen"></div>
      <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>

      {/* Floating blurry orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute w-64 h-64 bg-purple-400/30 rounded-full blur-3xl animate-float"
          style={{
            top: "15%",
            left: "10%",
            animationDuration: "25s",
            opacity: 0.2,
          }}
        ></div>
        <div
          className="absolute w-80 h-80 bg-indigo-500/20 rounded-full blur-3xl animate-float-slow"
          style={{
            top: "60%",
            left: "75%",
            animationDuration: "35s",
            opacity: 0.15,
          }}
        ></div>
        <div
          className="absolute w-48 h-48 bg-purple-600/20 rounded-full blur-3xl animate-float-reverse"
          style={{
            top: "75%",
            left: "15%",
            animationDuration: "30s",
            opacity: 0.1,
          }}
        ></div>
        <div
          className="absolute w-72 h-72 bg-indigo-400/20 rounded-full blur-3xl animate-float"
          style={{
            top: "25%",
            left: "80%",
            animationDuration: "28s",
            opacity: 0.2,
          }}
        ></div>
        <div
          className="absolute w-40 h-40 bg-purple-300/20 rounded-full blur-3xl animate-float-slow"
          style={{
            top: "42%",
            left: "30%",
            animationDuration: "32s",
            opacity: 0.15,
          }}
        ></div>
      </div>

      {/* Content */}
      <div className="container mx-auto px-6 relative z-10">
        <motion.div
          className="max-w-4xl mx-auto text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-5xl md:text-7xl font-black text-white mb-6 leading-tight">
            {isFrench ? (
              <>
                Partenaires de <br />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
                  Fabrication de Premier Plan
                </span>
              </>
            ) : (
              <>
                Industry-Leading <br />
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
                  Manufacturing Partners
                </span>
              </>
            )}
          </h1>

          <p className="text-xl text-purple-100 mb-10 max-w-3xl mx-auto">
            {isFrench
              ? "Reliant les fabricants d'électronique canadiens avec des solutions technologiques de pointe depuis 1995."
              : "Connecting Canadian electronics manufacturers with cutting-edge technology solutions since 1995."}
          </p>

          <div className="flex justify-center mt-10">
            <div className="bg-white/10 backdrop-blur-md rounded-xl py-2 px-4 border border-white/20">
              <div className="flex items-center space-x-3">
                <div className="flex items-center bg-purple-600/30 rounded-lg py-0.5 px-2">
                  <span className="text-2xl font-bold text-white mr-1">
                    {manufacturersCount}
                  </span>
                  <span className="text-purple-200 text-sm">
                    {isFrench ? "Partenaires" : "Partners"}
                  </span>
                </div>
                <span className="text-white/50">•</span>
                <div className="flex items-center">
                  <span className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 to-indigo-300 mr-1">
                    25+
                  </span>
                  <span className="text-purple-200 text-sm">
                    {isFrench ? "Ans d'Excellence" : "Years of Excellence"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ManufacturersHeroSection;
