import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import heroImage from "../assets/images/production_line-hero.png";
import BlobBackground from "../components/BlobBackground";

const HeroSection = ({ isFrench }) => {
  return (
    <section className="relative pt-12 md:pt-40 pb-20 md:pb-32 overflow-hidden bg-white max-w-full">
      <BlobBackground />
      <div className="container mx-auto px-6 relative z-10">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 lg:pr-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-display font-bold text-gray-900 mb-6 leading-tight">
                {isFrench ? (
                  <>
                    Représentant de{" "}
                    <span className="text-primary-600">
                      Fabricants d'Électronique
                    </span>{" "}
                    de Premier Plan
                  </>
                ) : (
                  <>
                    Leading Electronics{" "}
                    <span className="text-primary-600">
                      Manufacturer's Representative
                    </span>
                  </>
                )}
              </h1>
              <p className="text-lg sm:text-xl text-gray-600 mb-8 leading-relaxed max-w-xl">
                {isFrench
                  ? "Northtouch Canada Inc. est une entreprise leader dans la représentation de fabricants d'électronique, offrant une large gamme de machines d'assemblage, de tests, de programmation, de vérification et de solutions de diagnostic de classe mondiale."
                  : "Northtouch Canada Inc. is a leading electronics manufacturer's agents company representing a wide array of world class assembly machines, test, programming, verification and diagnostic solutions."}
              </p>
              <div className="flex flex-wrap gap-4">
                <Link
                  to={isFrench ? "/fr/contact" : "/contact"}
                  className="btn-primary"
                >
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    />
                  </svg>
                  {isFrench ? "Contactez-Nous" : "Contact Us"}
                </Link>
                <a href="mailto:sales@northtouch.com" className="btn-outline">
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  {isFrench
                    ? "Demander une Fiche Technique"
                    : "Request Line Card"}
                </a>
              </div>
            </motion.div>
          </div>
          <div className="lg:w-1/2 mt-12 lg:mt-0">
            <motion.div
              className="relative mx-auto max-w-full lg:max-w-none"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <div className="absolute -inset-4 bg-gradient-to-br from-primary-500 to-purple-600 rounded-xl opacity-20 blur-lg"></div>
              <div className="absolute -inset-6 bg-primary-100 rounded-xl -z-10 transform -rotate-3"></div>
              <div className="relative rounded-xl overflow-hidden shadow-xl">
                <img
                  src={heroImage}
                  alt={
                    isFrench
                      ? "Fabrication Électronique"
                      : "Electronics Manufacturing"
                  }
                  className="w-full h-auto"
                />
                <div className="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black/50 to-transparent"></div>
              </div>
              <div className="absolute top-4 right-4 bg-white/80 backdrop-blur-sm px-4 py-2 rounded-lg shadow-sm">
                <p className="text-sm font-medium text-primary-800">
                  {isFrench
                    ? "Plus de 25 Ans d'Excellence"
                    : "25+ Years of Excellence"}
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
