import React from "react";
import { motion } from "framer-motion";
import FeatureItem from "../components/FeatureItem";

const WhyChooseUsSection = ({ isFrench }) => {
  return (
    <section className="py-24 relative bg-gray-50">
      <div className="absolute inset-0 bg-dotted-pattern bg-[length:20px_20px]"></div>
      <div className="container mx-auto px-6 relative">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div
            className="lg:w-1/2 order-2 lg:order-1"
            initial={{ opacity: 0, x: -30 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="mb-2">
              <span className="text-xs uppercase tracking-wider text-primary-700 font-semibold bg-primary-50 py-1 px-3 rounded-full">
                {isFrench ? "Nos Avantages" : "Our Advantages"}
              </span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8">
              {isFrench
                ? "Pourquoi Choisir Northtouch ?"
                : "Why Choose Northtouch?"}
            </h2>
            <div className="space-y-8">
              <FeatureItem
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-primary-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                }
                title={
                  isFrench ? "Expertise de l'Industrie" : "Industry Expertise"
                }
                description={
                  isFrench
                    ? "Avec plus de 25 ans dans l'industrie de la fabrication électronique, nous apportons des connaissances et une expérience inégalées pour vous aider à trouver les solutions parfaites."
                    : "With over 25 years in the electronics manufacturing industry, we bring unparalleled knowledge and experience to help you find the perfect solutions."
                }
                delay={0.1}
              />
              <FeatureItem
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-primary-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                }
                title={isFrench ? "Support Dédié" : "Dedicated Support"}
                description={
                  isFrench
                    ? "Nous fournissons un support complet tout au long du processus, de la sélection à la mise en œuvre et au-delà, assurant votre succès."
                    : "We provide comprehensive support throughout the entire process, from selection to implementation and beyond, ensuring your success."
                }
                delay={0.2}
              />
              <FeatureItem
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-primary-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                }
                title={
                  isFrench ? "Solutions de Pointe" : "Cutting-Edge Solutions"
                }
                description={
                  isFrench
                    ? "Nous représentons uniquement les fabricants les plus innovants et fiables de l'industrie, vous garantissant la meilleure technologie disponible pour vos besoins de fabrication."
                    : "We represent only the most innovative and reliable manufacturers in the industry, ensuring you get the best technology available for your manufacturing needs."
                }
                delay={0.3}
              />
            </div>
          </motion.div>

          <motion.div
            className="lg:w-1/2 order-1 lg:order-2"
            initial={{ opacity: 0, x: 30 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="relative">
              <div className="absolute -inset-4 bg-primary-100 rounded-xl -z-10 transform rotate-3"></div>
              <div className="glass-card p-8 rounded-xl relative">
                <div className="absolute top-0 right-0 w-32 h-32 bg-primary-100 rounded-full filter blur-2xl opacity-50 -z-10"></div>
                <div className="absolute bottom-0 left-0 w-32 h-32 bg-purple-100 rounded-full filter blur-2xl opacity-50 -z-10"></div>

                <div className="text-center mb-8">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary-100 text-primary-700 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                      />
                    </svg>
                  </div>
                  <h3 className="text-2xl font-semibold text-gray-900 mb-2">
                    {isFrench
                      ? "Solutions de Pointe pour l'Industrie"
                      : "Industry-Leading Solutions"}
                  </h3>
                  <p className="text-gray-600">
                    {isFrench
                      ? "Nos clients bénéficient des avantages de travailler avec les meilleurs fabricants de l'industrie."
                      : "Our customers enjoy the benefits of working with the industry's top manufacturers."}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-white p-4 rounded-lg shadow-sm text-center">
                    <div className="text-3xl font-bold text-primary-600 mb-1">
                      25+
                    </div>
                    <div className="text-gray-700">
                      {isFrench ? "Ans d'Expérience" : "Years Experience"}
                    </div>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-sm text-center">
                    <div className="text-3xl font-bold text-primary-600 mb-1">
                      10+
                    </div>
                    <div className="text-gray-700">
                      {isFrench ? "Partenaires Mondiaux" : "Global Partners"}
                    </div>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-sm text-center">
                    <div className="text-3xl font-bold text-primary-600 mb-1">
                      100+
                    </div>
                    <div className="text-gray-700">
                      {isFrench ? "Produits" : "Products"}
                    </div>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-sm text-center">
                    <div className="text-3xl font-bold text-primary-600 mb-1">
                      500+
                    </div>
                    <div className="text-gray-700">
                      {isFrench ? "Clients Satisfaits" : "Happy Clients"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsSection;
