import React from "react";
import { motion } from "framer-motion";

const ServiceCard = ({ icon, title, description, delay = 0 }) => {
  return (
    <motion.div
      className="interactive-card relative group p-8 z-10 bg-white backdrop-blur-sm"
      initial={{ opacity: 0, y: 30 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: delay }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-primary-50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl z-0"></div>
      <div className="relative z-10">
        <div className="w-14 h-14 rounded-lg bg-primary-100 flex items-center justify-center mb-6 text-primary-600 transition-all duration-300 group-hover:scale-110 group-hover:bg-primary-200 group-hover:shadow-md">
          {icon}
        </div>
        <h3 className="text-xl font-semibold mb-3 text-gray-800 group-hover:text-primary-700 transition-colors">
          {title}
        </h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </motion.div>
  );
};

export default ServiceCard;
