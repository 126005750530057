import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

// Import manufacturer logos
import acculogicLogo from "../assets/images/Acculogic-logo.png";
import aegisLogo from "../assets/images/AEGIS-logo.png";
import asmptLogo from "../assets/images/asmpt-logo.png";
import iblLogo from "../assets/images/ibl-logo.svg";
import jtagLogo from "../assets/images/JTAG-logo.png";
import kicLogo from "../assets/images/KIC-logo.png";
import kurtzeErsaLogo from "../assets/images/kurtz-ersa.svg";
import komaxLogo from "../assets/images/Logo_Komax.png";
import flyingProberImage from "../assets/images/production_line-hero.png"; // Using this as placeholder
import solderingEquipmentImage from "../assets/images/production_line-hero.png"; // Using this as placeholder

// Import sections
import ManufacturersHeroSection from "../sections/ManufacturersHeroSection";
import ManufacturersCategoriesSection from "../sections/ManufacturersCategoriesSection";
import ManufacturersGridSection from "../sections/ManufacturersGridSection";
import ManufacturersCTASection from "../sections/ManufacturersCTASection";

const ManufacturersPage = () => {
  const location = useLocation();
  const isFrench = location.pathname.startsWith("/fr");
  const [activeFilter, setActiveFilter] = useState("All");
  const [filteredManufacturers, setFilteredManufacturers] = useState([]);

  // Translations for categories - memoized
  const getCategoryTranslation = useCallback(
    (category) => {
      if (!isFrench) return category;

      const translations = {
        All: "Tous",
        Testing: "Test",
        Software: "Logiciel",
        Assembly: "Assemblage",
        Soldering: "Soudage",
        Programming: "Programmation",
        Thermal: "Thermique",
        Automation: "Automatisation",
      };

      return translations[category] || category;
    },
    [isFrench]
  );

  // Translations for manufacturer descriptions - memoized
  const getTranslatedManufacturers = useCallback(() => {
    const manufacturersData = [
      {
        id: 1,
        name: "Acculogic",
        logo: acculogicLogo,
        description: isFrench
          ? "Fabricant canadien de Flying Prober, ICT, système de test fonctionnel. Fournit des services de test et d'intégration de systèmes."
          : "Canadian manufacturer of Flying Prober, ICT, Functional test system. Provides test service and system integration.",
        website: "https://acculogic.com/",
        category: isFrench ? "Test" : "Testing",
        featured: true,
        image: flyingProberImage,
      },
      {
        id: 2,
        name: "Aegis Software",
        logo: aegisLogo,
        description: isFrench
          ? "Permettre à tous les fabricants de toute taille de bénéficier des avantages de l'Industrie 4.0, via l'automatisation, l'intelligence et la connectivité."
          : "To enable all manufacturers of any size to achieve Industry 4.0 benefits, via automation, intelligence, and connectivity.",
        website: "https://www.aiscorp.com/",
        category: isFrench ? "Logiciel" : "Software",
      },
      {
        id: 3,
        name: isFrench
          ? "Conditionnement Avancé ASMPT Semiconductor Solutions"
          : "Advanced Packaging ASMPT Semiconductor Solutions",
        logo: asmptLogo,
        description: isFrench
          ? "Équipements comprenant des Die & Flip-Chip Bonder, Wire Bonder, équipements d'encapsulation, testeurs et trieurs, découpe laser et équipements LED."
          : "Equipment including Die & Flip-Chip Bonder, Wire Bonder, Encapsulation Equipment, Tester & Sorter, Laser Dicing, and LED Equipment.",
        website: "https://semi.asmpt.com/en/",
        category: isFrench ? "Assemblage" : "Assembly",
      },
      {
        id: 5,
        name: "IBL",
        logo: iblLogo,
        description: isFrench
          ? "Leader mondial dans la technologie des machines de soudage en phase vapeur."
          : "World leader in Vapour Phase Soldering Machine Technology.",
        website: "https://ibltech.com/en/",
        category: isFrench ? "Soudage" : "Soldering",
        featured: true,
        image: solderingEquipmentImage,
      },
      {
        id: 6,
        name: "JTAG Technologies",
        logo: jtagLogo,
        description: isFrench
          ? "Outils de test et de programmation utilisant la technologie Boundary Scan. Intégrateur pour plusieurs plateformes de test."
          : "Test and programming tools using Boundary Scan technology. Integrator for multiple test platform.",
        website: "https://jtag.northtouch.com/",
        category: isFrench ? "Programmation" : "Programming",
      },
      {
        id: 7,
        name: "KIC Thermal",
        logo: kicLogo,
        description: isFrench
          ? "Leader du marché dans le contrôle des processus de profilage thermique et de refusion et les technologies de fours intelligents."
          : "Market leader in reflow and thermal profiling process control and smart oven technologies.",
        website: "https://kicthermal.com/",
        category: isFrench ? "Thermique" : "Thermal",
      },
      {
        id: 8,
        name: "Komax",
        logo: komaxLogo,
        description: isFrench
          ? "Pionnier et leader du marché dans le domaine du traitement automatisé des fils."
          : "Pioneer and market leader in the field of automated wire processing.",
        website: "https://www.komaxgroup.com/",
        category: isFrench ? "Automatisation" : "Automation",
      },
      {
        id: 9,
        name: "Ersa",
        logo: kurtzeErsaLogo,
        description: isFrench
          ? "Leader compétitif dans le processus de soudage avec four à refusion, soudage sélectif, vague, retouche et système d'inspection."
          : "Competitive leader in soldering process with Reflow oven, Selective soldering, Wave, Rework and inspection system.",
        website: "https://www.kurtzersa.com/homepage.html",
        category: isFrench ? "Soudage" : "Soldering",
      },
    ];

    return manufacturersData;
  }, [isFrench]);

  // Wrap manufacturers array in useMemo
  const manufacturers = useMemo(
    () => getTranslatedManufacturers(),
    [getTranslatedManufacturers]
  );

  // Extract unique categories
  const categories = useMemo(() => {
    const allCategories = [
      "All",
      ...new Set(manufacturers.map((m) => m.category)),
    ];
    if (isFrench) {
      return allCategories.map((category) => getCategoryTranslation(category));
    }
    return allCategories;
  }, [manufacturers, isFrench, getCategoryTranslation]);

  // Filter manufacturers based on category
  useEffect(() => {
    let result = manufacturers;

    if (activeFilter !== (isFrench ? "Tous" : "All")) {
      result = result.filter((m) => m.category === activeFilter);
    }

    setFilteredManufacturers(result);
  }, [activeFilter, manufacturers, isFrench]);

  const handleFilterClick = (category) => {
    setActiveFilter(category);
  };

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "Nos Fabricants | Northtouch Canada Inc."
            : "Our Manufacturers | Northtouch Canada Inc."}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "Northtouch Canada représente des fabricants de classe mondiale dans l'industrie électronique offrant des machines d'assemblage, de test, de programmation, de vérification et des solutions de diagnostic."
              : "Northtouch Canada represents world-class manufacturers in the electronics industry offering assembly machines, test, programming, verification and diagnostic solutions."
          }
        />
      </Helmet>

      <ManufacturersHeroSection
        isFrench={isFrench}
        manufacturersCount={manufacturers.length}
      />

      <ManufacturersCategoriesSection
        categories={categories}
        activeFilter={activeFilter}
        onFilterClick={handleFilterClick}
        isFrench={isFrench}
      />

      <ManufacturersGridSection
        filteredManufacturers={filteredManufacturers}
        manufacturersTotal={manufacturers.length}
        activeFilter={activeFilter}
        isFrench={isFrench}
        setActiveFilter={setActiveFilter}
      />

      <ManufacturersCTASection isFrench={isFrench} />
    </>
  );
};

export default ManufacturersPage;
