import React from "react";
import { motion } from "framer-motion";

const ManufacturerLogo = ({ logo, alt, website, delay = 0 }) => {
  return (
    <motion.a
      href={website}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-center py-6 px-8 bg-white rounded-xl shadow-card transition-all duration-300 hover:shadow-card-hover transform hover:-translate-y-1"
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.4, delay }}
    >
      <img
        src={logo}
        alt={alt}
        className="h-16 w-auto object-contain lg:grayscale lg:hover:grayscale-0 transition-all duration-300 transform hover:scale-105"
      />
    </motion.a>
  );
};

export default ManufacturerLogo;
