import React from "react";
import { motion } from "framer-motion";
import ContactForm from "../components/ContactForm";

const ContactFormSection = ({ isFrench }) => {
  return (
    <section className="py-12 md:py-16 bg-white relative">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="container mx-auto px-6 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="max-w-3xl mx-auto"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-8 text-center">
            {isFrench ? "Envoyez-nous un message" : "Send us a message"}
          </h2>
          <div className="relative perspective-1000">
            <motion.div
              className="bg-white rounded-2xl shadow-2xl p-8 transform-gpu hover:scale-[1.02] transition-transform duration-300"
              style={{
                transformStyle: "preserve-3d",
                transform: "translateZ(0)",
              }}
              whileHover={{
                rotateX: 2,
                rotateY: 2,
                scale: 1.02,
                transition: { duration: 0.3 },
              }}
            >
              <ContactForm isFrench={isFrench} />
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactFormSection;
