import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";

const NotFoundPage = () => {
  const location = useLocation();
  const isFrench = location.pathname.startsWith("/fr");

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "404 - Page Non Trouvée | NorthTouch"
            : "404 - Page Not Found | NorthTouch"}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "La page que vous cherchez n'a pas pu être trouvée."
              : "The page you are looking for could not be found."
          }
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="flex items-center justify-center min-h-[60vh] bg-gray-50 px-4">
        <div className="text-center">
          <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
          <h2 className="text-2xl font-semibold text-gray-700 mb-6">
            {isFrench ? "Page Non Trouvée" : "Page Not Found"}
          </h2>
          <p className="text-gray-600 mb-8">
            {isFrench
              ? "La page que vous recherchez a peut-être été supprimée, son nom a été modifié ou elle est temporairement indisponible."
              : "The page you are looking for might have been removed, had its name changed, or is temporarily unavailable."}
          </p>
          <Link
            to={isFrench ? "/fr" : "/"}
            className="inline-block bg-blue-600 text-white px-6 py-3 rounded-md font-medium hover:bg-blue-700 transition-colors"
          >
            {isFrench ? "Retourner à l'accueil" : "Go back home"}
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
