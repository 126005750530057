import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ScrollToTopButton from '../components/ScrollToTopButton';
import Breadcrumbs from '../components/Breadcrumbs';

const MainLayout = () => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const isFrench = location.pathname.startsWith('/fr');
  
  // Handle page transitions
  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoaded(true);
  }, [location.pathname]);

  // Page transition variants
  const pageVariants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.5,
        when: 'beforeChildren'
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3
      }
    }
  };

  // Dynamic titles based on current route
  const getTitle = () => {
    const path = isFrench ? location.pathname.replace('/fr', '') : location.pathname;
    
    if (path === '/' || path === '') {
      return isFrench 
        ? 'Northtouch Canada Inc. - Représentant de premier plan de fabricants d\'électronique au Canada'
        : 'Northtouch Canada Inc. - Leading Electronics Manufacturer\'s Representative in Canada';
    }
    
    if (path === '/manufacturers') {
      return isFrench 
        ? 'Nos Fabricants et Partenaires | Northtouch Canada Inc.'
        : 'Our Manufacturers & Partners | Northtouch Canada Inc.';
    }
    
    if (path === '/products') {
      return isFrench 
        ? 'Solutions et Produits de Fabrication Électronique | Northtouch Canada Inc.'
        : 'Electronic Manufacturing Solutions & Products | Northtouch Canada Inc.';
    }
    
    if (path === '/news') {
      return isFrench 
        ? 'Actualités et Mises à Jour de l\'Industrie | Northtouch Canada Inc.'
        : 'Industry News & Updates | Northtouch Canada Inc.';
    }
    
    if (path === '/contact') {
      return isFrench 
        ? 'Contactez-Nous pour des Solutions de Fabrication Électronique | Northtouch Canada Inc.'
        : 'Contact Us for Electronic Manufacturing Solutions | Northtouch Canada Inc.';
    }
    
    return isFrench 
      ? 'Northtouch Canada Inc. - Solutions de Fabrication Électronique'
      : 'Northtouch Canada Inc. - Electronics Manufacturing Solutions';
  };

  // Get the canonical path
  const getCanonicalPath = () => {
    return `https://northtouch.com${location.pathname}`;
  };

  // Get alternate language URL
  const getAlternateLanguageUrl = () => {
    if (isFrench) {
      return `https://northtouch.com${location.pathname.replace('/fr', '')}`;
    } else {
      return `https://northtouch.com/fr${location.pathname}`;
    }
  };

  // Get page description based on language
  const getDescription = () => {
    return isFrench
      ? "Northtouch Canada Inc. est une entreprise représentante de fabricants d'électronique de premier plan spécialisée dans les machines d'assemblage, les tests, la programmation, la vérification et les solutions de diagnostic pour le marché canadien."
      : "Northtouch Canada Inc. is a leading electronics manufacturer's representatives company specializing in assembly machines, test, programming, verification and diagnostic solutions for the Canadian market.";
  };

  // Get OG description based on language
  const getOgDescription = () => {
    return isFrench
      ? "Représentants de fabricants d'électronique de premier plan spécialisés dans les machines d'assemblage, les tests, la programmation, la vérification et les solutions de diagnostic pour l'industrie électronique canadienne."
      : "Leading electronics manufacturer's representatives specializing in assembly machines, test, programming, verification and diagnostic solutions for the Canadian electronics industry.";
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={getDescription()} />
        <meta name="author" content="Northtouch Canada Inc." />
        <meta name="robots" content="index, follow" />
        <title>{getTitle()}</title>
        <link rel="canonical" href={getCanonicalPath()} />
        <link rel="alternate" hrefLang={isFrench ? "en" : "fr"} href={getAlternateLanguageUrl()} />
        
        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        
        {/* Open Graph / Social Media */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={getCanonicalPath()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:description" content={getOgDescription()} />
        <meta property="og:image" content="https://northtouch.com/og-image.jpg" />
        <meta property="og:site_name" content="Northtouch Canada Inc." />
        <meta property="og:locale" content={isFrench ? "fr_CA" : "en_CA"} />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getOgDescription()} />
        <meta name="twitter:image" content="https://northtouch.com/twitter-image.jpg" />
        <meta name="twitter:site" content="@northtouch" />
        
        {/* Google Fonts */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet" />
        
        {/* Additional SEO metadata */}
        <meta name="geo.region" content="CA-QC" />
        <meta name="geo.placename" content="Kirkland" />
      </Helmet>
      <div className="flex flex-col min-h-screen bg-white">
        {/* Loading screen */}
        {!isLoaded && (
          <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
            <div className="w-16 h-16 border-t-4 border-primary-600 border-solid rounded-full animate-spin"></div>
          </div>
        )}
        
        {/* Header */}
        <Header isFrench={isFrench} />
        
        {/* Breadcrumbs for SEO and navigation */}
        <Breadcrumbs isFrench={isFrench} />
        
        {/* Main content with page transitions */}
        <main className="flex-grow">
          <AnimatePresence mode="wait">
            <motion.div
              key={location.pathname}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={pageVariants}
              className="page-container"
            >
              <Outlet />
            </motion.div>
          </AnimatePresence>
        </main>
        
        {/* Decorative elements */}
        <div className="fixed top-0 left-0 w-full h-full pointer-events-none z-0 overflow-hidden">
          <div className="absolute top-0 right-0 w-96 h-96 bg-primary-100 rounded-full filter blur-3xl opacity-30 transform translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-100 rounded-full filter blur-3xl opacity-30 transform -translate-x-1/2 translate-y-1/2"></div>
        </div>
        
        {/* Footer */}
        <Footer isFrench={isFrench} />
        
        {/* Scroll to top button */}
        <ScrollToTopButton />
      </div>
    </>
  );
};

export default MainLayout; 