import React from "react";

const CategoryFilter = ({ category, active, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`relative px-5 py-2 rounded-full text-sm font-medium transition-all duration-300 overflow-hidden
        ${
          active ? "text-white shadow-lg" : "text-gray-600 hover:text-gray-800"
        }`}
    >
      <span
        className={`absolute inset-0 ${
          active ? "opacity-100" : "opacity-0"
        } transition-opacity duration-300`}
      >
        <span className="absolute inset-0 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full"></span>
        <span className="absolute inset-0 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full blur-md opacity-70"></span>
      </span>
      <span className="relative">{category}</span>
    </button>
  );
};

export default CategoryFilter;
