import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

// Import sections
import ProductsHeroSection from "../sections/ProductsHeroSection";
import ProductsGridSection from "../sections/ProductsGridSection";

// Import logos
import acculogicLogo from "../assets/images/Acculogic-logo.png";
import aegisLogo from "../assets/images/AEGIS-logo.png";
import asmptLogo from "../assets/images/asmpt-logo.png";
import iblLogo from "../assets/images/ibl-logo.svg";
import jtagLogo from "../assets/images/JTAG-logo.png";
import kicLogo from "../assets/images/KIC-logo.png";
import kurtzeErsaLogo from "../assets/images/kurtz-ersa.svg";
import komaxLogo from "../assets/images/Logo_Komax.png";
import adtLogo from "../assets/images/adt_logo.png";

const ProductsPage = () => {
  const location = useLocation();
  const isFrench = location.pathname.startsWith("/fr");

  // Get translated products based on language
  const products = useMemo(() => {
    return [
      // Manufacturing Equipment
      {
        id: 1,
        name: "Advanced Packaging ASMPT Semiconductor Solutions",
        frenchName: "Emballage Avancé ASMPT Semiconductor Solutions",
        logo: asmptLogo,
        description:
          "Maximizing Efficiency in IC & Discrete Component Production with advanced semiconductor manufacturing equipment.",
        frenchDescription:
          "Maximiser l'efficacité dans la production de composants IC et discrets avec des équipements avancés de fabrication de semi-conducteurs.",
        website: "https://semi.asmpt.com/en/",
        category: "Manufacturing Equipment",
      },
      {
        id: 2,
        name: "Aegis Software",
        frenchName: "Aegis Software",
        logo: aegisLogo,
        description:
          "Enabling manufacturers of any size to achieve Industry 4.0 benefits via automation, intelligence, and connectivity.",
        frenchDescription:
          "Permettre aux fabricants de toute taille de bénéficier des avantages de l'Industrie 4.0 grâce à l'automatisation, l'intelligence et la connectivité.",
        website: "https://www.aiscorp.com/",
        category: "Manufacturing Equipment",
      },
      {
        id: 3,
        name: "IBL",
        frenchName: "IBL",
        logo: iblLogo,
        description:
          "World leader in Vapour Phase Soldering Machine Technology.",
        frenchDescription:
          "Leader mondial de la technologie des machines de soudage en phase vapeur.",
        website: "https://ibltech.com/en/",
        category: "Manufacturing Equipment",
      },
      {
        id: 4,
        name: "Ersa",
        frenchName: "Ersa",
        logo: kurtzeErsaLogo,
        description:
          "Competitive leader in soldering process with Reflow oven, Selective soldering, Wave, Rework and inspection system.",
        frenchDescription:
          "Leader concurrentiel dans le processus de soudure avec four à refusion, soudure sélective, vague, retouche et système d'inspection.",
        website: "https://www.kurtzersa.com/homepage.html",
        category: "Manufacturing Equipment",
      },
      {
        id: 5,
        name: "KIC Thermal",
        frenchName: "KIC Thermal",
        logo: kicLogo,
        description:
          "Market leader in reflow and thermal profiling process control and smart oven technologies.",
        frenchDescription:
          "Leader du marché en contrôle de processus de profilage thermique et de refusion et technologies de fours intelligents.",
        website: "https://kicthermal.com/",
        category: "Manufacturing Equipment",
      },
      {
        id: 6,
        name: "Komax",
        frenchName: "Komax",
        logo: komaxLogo,
        description:
          "Pioneer and market leader in the field of automated wire processing.",
        frenchDescription:
          "Pionnier et leader du marché dans le domaine du traitement automatisé des fils.",
        website: "https://www.komaxgroup.com/",
        category: "Manufacturing Equipment",
      },

      // Programming and Testing
      {
        id: 7,
        name: "Acculogic",
        frenchName: "Acculogic",
        logo: acculogicLogo,
        description:
          "Canadian manufacturer of Flying Prober, ICT, Functional test system. Provides test service and system integration.",
        frenchDescription:
          "Fabricant canadien de systèmes Flying Prober, ICT, de test fonctionnel. Fournit des services de test et d'intégration de systèmes.",
        website: "https://acculogic.com/",
        category: "Programming & Testing",
      },
      {
        id: 8,
        name: "JTAG Technologies",
        frenchName: "JTAG Technologies",
        logo: jtagLogo,
        description:
          "Test and programming tools using Boundary Scan technology. Integrator for multiple test platform.",
        frenchDescription:
          "Outils de test et de programmation utilisant la technologie Boundary Scan. Intégrateur pour plusieurs plateformes de test.",
        website: "https://jtag.northtouch.com/",
        category: "Programming & Testing",
      },
      {
        id: 9,
        name: "Advanced Dicing Technologies (ADT)",
        frenchName: "Technologies Avancées de Découpe (ADT)",
        logo: adtLogo,
        description:
          "Manufactures unique dicing saws and blades, answering the needs of various industries, such as the semiconductor and microelectronic industry.",
        frenchDescription:
          "Fabrication de scies et de lames de découpe uniques, répondant aux besoins de diverses industries, telles que l'industrie des semi-conducteurs et de la microélectronique.",
        website: "https://www.adt-dicing.com/",
        category: "Programming & Testing",
      },
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "Produits | Northtouch Canada Inc. - Solutions de Fabrication Électronique"
            : "Products | Northtouch Canada Inc. - Electronics Manufacturing Solutions"}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "Découvrez notre gamme complète de solutions de fabrication électronique, d'équipements de test et de machines d'assemblage de pointe."
              : "Explore our comprehensive range of electronics manufacturing solutions, testing equipment, and cutting-edge assembly machines."
          }
        />
      </Helmet>

      <ProductsHeroSection isFrench={isFrench} />
      <ProductsGridSection products={products} isFrench={isFrench} />
    </>
  );
};

export default ProductsPage;
