import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ManufacturerLogo from "../components/ManufacturerLogo";

// Import manufacturer logos
import acculogicLogo from "../assets/images/Acculogic-logo.png";
import aegisLogo from "../assets/images/AEGIS-logo.png";
import asmptLogo from "../assets/images/asmpt-logo.png";
import komaxLogo from "../assets/images/Logo_Komax.png";
import iblLogo from "../assets/images/ibl-logo.svg";
import jtagLogo from "../assets/images/JTAG-logo.png";
import kicLogo from "../assets/images/KIC-logo.png";
import kurtzErsaLogo from "../assets/images/kurtz-ersa.svg";

const TrustedPartnersSection = ({ isFrench }) => {
  return (
    <section className="py-12 md:py-16 bg-gray-50 relative">
      <div className="absolute inset-0 bg-dotted-pattern bg-[length:20px_20px]"></div>
      <div className="container mx-auto px-6 relative">
        <motion.div
          className="text-center mb-8 md:mb-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <div className="inline-block mb-2">
            <span className="text-xs uppercase tracking-wider text-primary-700 font-semibold bg-primary-50 py-1 px-3 rounded-full">
              {isFrench ? "Partenariats" : "Partnerships"}
            </span>
          </div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            {isFrench ? "Partenaires de Confiance" : "Trusted Partners"}
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            {isFrench
              ? "Nous représentons des fabricants de classe mondiale, apportant leurs solutions innovantes au marché canadien."
              : "We represent world-class manufacturers, bringing their innovative solutions to the Canadian market."}
          </p>
        </motion.div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6 lg:gap-8">
          <ManufacturerLogo
            logo={acculogicLogo}
            alt="Acculogic"
            website="https://acculogic.com/"
            delay={0.1}
          />
          <ManufacturerLogo
            logo={aegisLogo}
            alt="AEGIS"
            website="https://www.aiscorp.com/"
            delay={0.2}
          />
          <ManufacturerLogo
            logo={asmptLogo}
            alt="ASMPT Semiconductor Solutions"
            website="https://semi.asmpt.com/en/"
            delay={0.3}
          />
          <ManufacturerLogo
            logo={komaxLogo}
            alt="Komax"
            website="https://www.komaxgroup.com/"
            delay={0.4}
          />
          <ManufacturerLogo
            logo={iblLogo}
            alt="IBL"
            website="https://ibltech.com/en/"
            delay={0.5}
          />
          <ManufacturerLogo
            logo={jtagLogo}
            alt="JTAG"
            website="https://jtag.northtouch.com/"
            delay={0.6}
          />
          <ManufacturerLogo
            logo={kicLogo}
            alt="KIC"
            website="https://kicthermal.com/"
            delay={0.7}
          />
          <ManufacturerLogo
            logo={kurtzErsaLogo}
            alt="Kurtz Ersa"
            website="https://www.kurtzersa.com/homepage.html"
            delay={0.8}
          />
        </div>

        <div className="text-center mt-12">
          <Link
            to={isFrench ? "/fr/manufacturers" : "/manufacturers"}
            className="inline-flex items-center text-primary-700 font-medium hover:text-primary-800 transition-colors animated-link"
          >
            {isFrench ? "Voir Tous les Fabricants" : "View All Manufacturers"}
            <svg
              className="ml-2 w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TrustedPartnersSection;
