import React from "react";
import { motion } from "framer-motion";

const ContactInfoCard = ({ icon, title, content }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-xl shadow-md flex items-start border border-gray-100 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
    >
      <div className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white p-3 rounded-lg mr-4 flex-shrink-0">
        {icon}
      </div>
      <div>
        <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
        <div className="text-gray-600">{content}</div>
      </div>
    </motion.div>
  );
};

const ContactInfoSection = ({ isFrench }) => {
  return (
    <section className="py-12 md:py-16 bg-white relative">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="container mx-auto px-6 relative">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          <ContactInfoCard
            icon={
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
            }
            title={isFrench ? "Téléphone" : "Phone"}
            content={
              <a
                href="tel:5148259611"
                className="text-gray-600 hover:text-purple-600 transition-colors"
              >
                514.825.9611
              </a>
            }
          />
          <ContactInfoCard
            icon={
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            }
            title="Email"
            content={
              <a
                href="mailto:sales@northtouch.com"
                className="text-gray-600 hover:text-purple-600 transition-colors"
              >
                sales@northtouch.com
              </a>
            }
          />
          <ContactInfoCard
            icon={
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            }
            title={isFrench ? "Adresse" : "Address"}
            content={
              <div className="text-gray-600">
                <p>3551 Boul St-Charles Suite 511</p>
                <p>Kirkland, Quebec H9H 3C4</p>
                <p>Canada</p>
              </div>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default ContactInfoSection;
