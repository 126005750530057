import React, { useState, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import ProductCard from "../components/shared/ProductCard";
import CategoryFilter from "../components/shared/CategoryFilter";

// Function to get category translation
const getCategoryTranslation = (category, isFrench) => {
  if (!isFrench) return category;

  const translations = {
    All: "Tous",
    "Manufacturing Equipment": "Équipement de Fabrication",
    "Programming & Testing": "Programmation et Test",
    "Consumables & Tools": "Consommables et Outils",
  };

  return translations[category] || category;
};

const ProductsGridSection = ({ products, isFrench }) => {
  const [activeFilter, setActiveFilter] = useState(isFrench ? "Tous" : "All");
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Extract unique categories
  const categories = useMemo(() => {
    const uniqueCategories = new Set(
      products.map((product) => product.category)
    );
    return ["All", ...Array.from(uniqueCategories)].map((category) =>
      getCategoryTranslation(category, isFrench)
    );
  }, [products, isFrench]);

  // Filter products based on active category
  useEffect(() => {
    const translatedActiveFilter = isFrench
      ? Object.entries(getCategoryTranslation).find(
          ([_, value]) => value === activeFilter
        )?.[0] || activeFilter
      : activeFilter;

    const filtered = products.filter(
      (product) =>
        translatedActiveFilter === "All" ||
        product.category === translatedActiveFilter
    );
    setFilteredProducts(filtered);
  }, [activeFilter, products, isFrench]);

  return (
    <section className="py-12 md:py-16 bg-white relative">
      <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>
      <div className="container mx-auto px-6 relative">
        {/* Category Filters */}
        <div className="flex flex-wrap gap-3 justify-center mb-12">
          {categories.map((category) => (
            <CategoryFilter
              key={category}
              category={category}
              active={category === activeFilter}
              onClick={() => setActiveFilter(category)}
            />
          ))}
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProducts.map((product, index) => (
            <ProductCard
              key={product.id}
              logo={product.logo}
              name={isFrench ? product.frenchName : product.name}
              description={
                isFrench ? product.frenchDescription : product.description
              }
              website={product.website}
              category={getCategoryTranslation(product.category, isFrench)}
              index={index}
              isFrench={isFrench}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductsGridSection;
