import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/northtouch-site-logo.png';

const Footer = ({ isFrench }) => {
  // Function to generate path with the language prefix
  const getPathPrefix = () => isFrench ? '/fr' : '';

  return (
    <footer className="bg-white border-t border-gray-100">
      
      <div className="bg-white py-16">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-10">
            <div className="md:col-span-2">
              <div>
                <Link to={`${getPathPrefix()}/`} className="inline-block mb-6">
                  <img src={logo} alt="Northtouch Canada Inc." className="h-12" />
                </Link>
                <p className="text-gray-600 leading-relaxed mb-8">
                  {isFrench 
                    ? "Entreprise de représentation de fabricants d'électronique de premier plan établie en 1995, au service de la communauté canadienne de fabrication électronique depuis plus de 25 ans."
                    : "Leading electronics manufacturer's agents company established in 1995, serving the Canadian Electronics Manufacturing Community for over 25 years."
                  }
                </p>
                <div className="flex space-x-4">
                  <SocialLink href="https://linkedin.com" ariaLabel="LinkedIn">
                    <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
                    </svg>
                  </SocialLink>
                  <SocialLink href="https://twitter.com" ariaLabel="Twitter">
                    <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                    </svg>
                  </SocialLink>
                  <SocialLink href="https://facebook.com" ariaLabel="Facebook">
                    <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                    </svg>
                  </SocialLink>
                </div>
              </div>
            </div>
            
            <div className="md:col-span-1">
              <h4 className="text-lg font-semibold mb-6 text-gray-900">{isFrench ? "Liens Rapides" : "Quick Links"}</h4>
              <ul className="space-y-3">
                <FooterLink to={`${getPathPrefix()}/`} label={isFrench ? "Accueil" : "Home"} />
                <FooterLink to={`${getPathPrefix()}/manufacturers`} label={isFrench ? "Fabricants" : "Manufacturers"} />
                <FooterLink to={`${getPathPrefix()}/products`} label={isFrench ? "Produits" : "Products"} />
                <FooterLink to={`${getPathPrefix()}/contact`} label={isFrench ? "Contact" : "Contact"} />
              </ul>
            </div>
            
            <div className="md:col-span-1">
              <h4 className="text-lg font-semibold mb-6 text-gray-900">{isFrench ? "Produits" : "Products"}</h4>
              <ul className="space-y-3">
                <FooterLink to={`${getPathPrefix()}/products`} label={isFrench ? "Équipement de Fabrication" : "Manufacturing Equipment"} />
                <FooterLink to={`${getPathPrefix()}/products`} label={isFrench ? "Solutions de Test" : "Testing Solutions"} />
                <FooterLink to={`${getPathPrefix()}/products`} label={isFrench ? "Consommables et Outils" : "Consumables & Tools"} />
              </ul>
            </div>
            
            <div className="md:col-span-2">
              <h4 className="text-lg font-semibold mb-6 text-gray-900">{isFrench ? "Contactez-Nous" : "Contact Us"}</h4>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="feature-icon-wrapper mt-0.5 mr-3">
                    <svg className="w-5 h-5 text-primary-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-700 font-medium">{isFrench ? "Adresse" : "Address"}</p>
                    <p className="text-gray-600">
                      Northtouch Canada Inc.<br />
                      3551 Boul. St-Charles, Suite 511<br />
                      Kirkland, Québec H9H 3C4
                    </p>
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="feature-icon-wrapper mr-3">
                    <svg className="w-5 h-5 text-primary-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-700 font-medium">{isFrench ? "Téléphone" : "Phone"}</p>
                    <a href="tel:5148259611" className="text-gray-600 hover:text-primary-700 transition-colors">
                      514.825.9611
                    </a>
                  </div>
                </li>
                <li className="flex items-center">
                  <div className="feature-icon-wrapper mr-3">
                    <svg className="w-5 h-5 text-primary-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-700 font-medium">{isFrench ? "Courriel" : "Email"}</p>
                    <a href="mailto:sales@northtouch.com" className="text-gray-600 hover:text-primary-700 transition-colors">
                      sales@northtouch.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="bg-white py-8">
        <div className="container mx-auto px-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-500 text-sm">
              &copy; {new Date().getFullYear()} Northtouch Canada Inc. {isFrench ? "Tous droits réservés." : "All rights reserved."}
            </p>
            <div className="mt-4 md:mt-0">
              <ul className="flex space-x-6 text-sm">
                <li>
                  <Link to={`${getPathPrefix()}/privacy-policy`} className="text-gray-500 hover:text-primary-600 transition-colors">
                    {isFrench ? "Politique de Confidentialité" : "Privacy Policy"}
                  </Link>
                </li>
                <li>
                  <Link to={`${getPathPrefix()}/terms-of-service`} className="text-gray-500 hover:text-primary-600 transition-colors">
                    {isFrench ? "Conditions d'Utilisation" : "Terms of Service"}
                  </Link>
                </li>
                <li>
                  <Link to={`${getPathPrefix()}/sitemap`} className="text-gray-500 hover:text-primary-600 transition-colors">
                    {isFrench ? "Plan du Site" : "Sitemap"}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          
          {/* Netfluence Attribution */}
          <div className="mt-6 flex justify-center">
            <NetfluenceAttribution isFrench={isFrench} />
          </div>
        </div>
      </div>
    </footer>
  );
};

// Social Link Component with pulsing hover effect
const SocialLink = ({ href, ariaLabel, children }) => {
  return (
    <a 
      href={href} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="bg-white text-primary-700 p-2.5 rounded-full shadow-sm flex items-center justify-center transition-all duration-300 hover:bg-primary-600 hover:text-white hover:shadow-md transform hover:scale-110"
      aria-label={ariaLabel}
    >
      {children}
    </a>
  );
};

// Footer Link Component with animated hover effect
const FooterLink = ({ to, label }) => {
  return (
    <li>
      <Link 
        to={to} 
        className="text-gray-600 hover:text-primary-700 transition-colors duration-300 inline-flex items-center animated-link"
      >
        {label}
      </Link>
    </li>
  );
};

// Netfluence Attribution Component with Modal
const NetfluenceAttribution = ({ isFrench }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button 
        onClick={() => setShowModal(true)}
        className="group relative overflow-hidden bg-white px-4 py-2 rounded-full border border-purple-300 hover:border-purple-500 hover:shadow-lg transition-all duration-300"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-purple-400 to-indigo-500 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-full"></div>
        <span className="relative z-10 flex items-center text-sm font-medium text-purple-600 group-hover:text-white transition-colors duration-300">
          {isFrench ? "Propulsé par" : "Powered by"}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-indigo-600 font-bold ml-1.5 group-hover:text-white transition-colors duration-300">Netfluence</span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1.5 transform group-hover:translate-x-1 transition-transform duration-300" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </button>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60" onClick={() => setShowModal(false)}>
          <div 
            className="relative bg-white rounded-2xl shadow-2xl max-w-md mx-4 overflow-hidden transform transition-all"
            onClick={e => e.stopPropagation()}
          >
            {/* Modal content */}
            <div className="px-8 py-8">
              {/* Purple gradient header */}
              <div className="absolute top-0 left-0 right-0 h-2 bg-gradient-to-r from-purple-500 to-indigo-600"></div>
              
              {/* Close button */}
              <button 
                onClick={() => setShowModal(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-700 transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {isFrench ? "Intéressé par un site web comme celui-ci ?" : "Interested in a website like this?"}
              </h3>
              
              <p className="text-gray-600 mb-6">
                {isFrench 
                  ? "Ce site web a été conçu et développé par Netfluence, une agence de développement web basée à Montréal, spécialisée dans la création de sites web beaux, réactifs et fonctionnels pour les entreprises."
                  : "This website was designed and developed by Netfluence, a Montreal-based web development agency specializing in creating beautiful, responsive, and functional websites for businesses."
                }
              </p>
              
              <div className="bg-gray-50 rounded-xl p-5 mb-6">
                <div className="flex items-center mb-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <a href="mailto:info@netfluence.ca" className="text-purple-600 hover:text-purple-800 font-medium">
                    info@netfluence.ca
                  </a>
                </div>
                <div className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-purple-600 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <a href="tel:5147927781" className="text-purple-600 hover:text-purple-800 font-medium">
                    (514) 792-7781
                  </a>
                </div>
              </div>
              
              <a 
                href="https://netfluence.ca" 
                target="_blank" 
                rel="noopener noreferrer"
                className="block w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-3 px-4 rounded-xl font-medium text-center hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
              >
                {isFrench ? "Visiter notre site web" : "Visit our website"}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer; 