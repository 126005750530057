import React from "react";
import CategoryFilter from "../components/shared/CategoryFilter";

const ManufacturersCategoriesSection = ({
  categories,
  activeFilter,
  onFilterClick,
  isFrench,
}) => {
  return (
    <section className="py-6 bg-white/80 border-y border-gray-200 shadow-sm">
      <div className="container mx-auto px-6">
        <div className="flex flex-wrap gap-3 justify-center">
          {categories.map((category) => (
            <CategoryFilter
              key={category}
              category={category}
              active={activeFilter === category}
              onClick={() => onFilterClick(category)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ManufacturersCategoriesSection;
