import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

// Import sections
import HeroSection from "../sections/HeroSection";
import ServicesSection from "../sections/ServicesSection";
import TrustedPartnersSection from "../sections/TrustedPartnersSection";
import WhyChooseUsSection from "../sections/WhyChooseUsSection";
import CTASection from "../sections/CTASection";

const HomePage = () => {
  const location = useLocation();
  const isFrench = location.pathname.startsWith("/fr");

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "Northtouch Canada Inc. | Représentant de Fabricants d'Électronique"
            : "Northtouch Canada Inc. | Electronics Manufacturer's Representative"}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "Northtouch Canada Inc. est une entreprise leader dans la représentation de fabricants d'électronique, offrant une large gamme de machines d'assemblage, de tests, de programmation, de vérification et de solutions de diagnostic de classe mondiale."
              : "Northtouch Canada Inc. is a leading electronics manufacturer's agents company representing a wide array of world class assembly machines, test, programming, verification and diagnostic solutions."
          }
        />
        <meta
          name="keywords"
          content={
            isFrench
              ? "représentant de fabricants électroniques, machines d'assemblage, équipement de test, solutions de programmation, systèmes de vérification, solutions de diagnostic, électronique canadienne, test électronique, test de PCB, assemblage de circuits imprimés"
              : "electronics manufacturer representative, assembly machines, test equipment, programming solutions, verification systems, diagnostic solutions, canadian electronics, electronics testing, PCB testing, circuit board assembly"
          }
        />

        {/* Structured data for local business */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Northtouch Canada Inc.",
            "image": "https://northtouch.com/og-image.jpg",
            "url": "https://northtouch.com",
            "telephone": "+15148259611",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "3551 Boul. St-Charles, Suite 511",
              "addressLocality": "Kirkland",
              "addressRegion": "Québec",
              "postalCode": "H9H 3C4",
              "addressCountry": "CA"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 45.437407,
              "longitude": -73.865433
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "09:00",
              "closes": "17:00"
            },
            "sameAs": [
              "https://www.linkedin.com/company/northtouch-canada-inc/"
            ]
          }
        `}
        </script>
      </Helmet>

      <HeroSection isFrench={isFrench} />
      <ServicesSection isFrench={isFrench} />
      <TrustedPartnersSection isFrench={isFrench} />
      <WhyChooseUsSection isFrench={isFrench} />
      <CTASection isFrench={isFrench} />
    </>
  );
};

export default HomePage;
