import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const CTASection = ({ isFrench }) => {
  return (
    <section className="py-20 bg-gradient-to-br from-primary-900 to-purple-900 text-white relative overflow-hidden">
      <div className="absolute top-0 left-0 w-full h-full bg-grid-pattern opacity-10"></div>
      <div className="absolute -bottom-32 -left-32 w-64 h-64 bg-white/10 rounded-full"></div>
      <div className="absolute -top-32 -right-32 w-64 h-64 bg-white/10 rounded-full"></div>

      <div className="container mx-auto px-6 relative z-10">
        <motion.div
          className="max-w-3xl mx-auto text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-3xl md:text-4xl font-display font-semibold mb-6">
            {isFrench
              ? "Prêt à Améliorer Votre Processus de Fabrication ?"
              : "Ready to Upgrade Your Manufacturing Process?"}
          </h2>
          <p className="text-xl text-purple-100 mb-10 leading-relaxed">
            {isFrench
              ? "Contactez-nous dès aujourd'hui pour découvrir comment nos solutions peuvent améliorer votre efficacité, votre qualité et vos résultats."
              : "Contact us today to learn how our solutions can improve your efficiency, quality, and bottom line."}
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <Link
              to={isFrench ? "/fr/contact" : "/contact"}
              className="bg-white text-primary-800 px-8 py-4 rounded-lg font-medium hover:bg-gray-100 transition-all duration-300 transform hover:scale-105 hover:shadow-lg inline-flex items-center"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {isFrench
                ? "Planifier une Consultation"
                : "Schedule a Consultation"}
            </Link>
            <a
              href="tel:5148259611"
              className="border-2 border-white text-white px-8 py-4 rounded-lg font-medium hover:bg-white/10 transition-all duration-300 transform hover:scale-105 inline-flex items-center"
            >
              <svg
                className="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              {isFrench
                ? "Appelez-Nous: 514.825.9611"
                : "Call Us: 514.825.9611"}
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default CTASection;
