import React from "react";
import { motion } from "framer-motion";

const ManufacturersCTASection = ({ isFrench }) => {
  return (
    <section className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-black z-0"></div>

      {/* Enhanced decorative elements */}
      <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>
      <div className="absolute right-1/4 top-1/4 w-96 h-96 bg-purple-600 rounded-full opacity-20 mix-blend-multiply filter blur-[100px]"></div>
      <div className="absolute left-1/4 bottom-1/4 w-96 h-96 bg-indigo-600 rounded-full opacity-20 mix-blend-multiply filter blur-[100px]"></div>

      <div className="container mx-auto px-6 relative z-10">
        <div className="max-w-4xl mx-auto">
          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              {isFrench
                ? "Prêt à Transformer Votre Processus de Fabrication ?"
                : "Ready to Transform Your Manufacturing Process?"}
            </h2>
            <p className="text-xl text-purple-200 mb-12 max-w-3xl mx-auto">
              {isFrench
                ? "Notre équipe d'experts vous aidera à trouver la solution technologique parfaite pour vos besoins spécifiques."
                : "Our team of experts will help you find the perfect technological solution for your specific needs."}
            </p>
            <div className="flex flex-col sm:flex-row gap-6 justify-center">
              <a
                href={isFrench ? "/fr/contact" : "/contact"}
                className="group relative px-8 py-4 rounded-xl font-bold transition-all duration-300 transform hover:scale-105 inline-flex items-center justify-center"
              >
                <div className="absolute inset-0 bg-white rounded-xl"></div>
                <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-indigo-500 opacity-0 group-hover:opacity-100 rounded-xl transition-opacity duration-300 blur-md -z-10"></div>
                <span className="relative z-10 flex items-center text-purple-900">
                  {isFrench ? "Contacter Nos Experts" : "Contact Our Experts"}
                  <svg
                    className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform duration-300"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </span>
              </a>
              <a
                href="mailto:sales@northtouch.com"
                className="group relative px-8 py-4 rounded-xl font-bold transition-all inline-flex items-center justify-center"
              >
                <div className="absolute inset-0 border-2 border-white/50 group-hover:border-white rounded-xl transition-colors duration-300"></div>
                <div className="absolute inset-0 bg-white/5 group-hover:bg-white/10 rounded-xl transition-colors duration-300"></div>
                <span className="relative z-10 flex items-center text-white">
                  {isFrench
                    ? "Demander une Fiche Technique"
                    : "Request Line Card"}
                  <svg
                    className="w-5 h-5 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ManufacturersCTASection;
