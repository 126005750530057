import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const Header = ({ isFrench }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const switchLanguage = () => {
    const currentPath = location.pathname;
    if (isFrench) {
      // If we're on a French page, navigate to the equivalent English page
      navigate(currentPath.replace("/fr", ""));
    } else {
      // If we're on an English page, navigate to the equivalent French page
      navigate(`/fr${currentPath}`);
    }
  };

  // Create path prefixes for navigation links based on current language
  const getPathPrefix = () => (isFrench ? "/fr" : "");

  return (
    <header
      className={`w-full z-50 transition-all duration-500 ${
        scrolled
          ? "py-3 backdrop-blur-lg bg-white/80 border-b border-white/20 shadow-md"
          : "py-5 bg-white"
      }`}
    >
      <div className="container mx-auto px-4">
        {/* Desktop Navigation - visible on iPad Pro and larger screens (lg breakpoint is 1024px) */}
        <div className="hidden lg:flex lg:justify-between lg:items-center lg:w-full">
          <DesktopNav
            isFrench={isFrench}
            getPathPrefix={getPathPrefix}
            currentPath={location.pathname}
            switchLanguage={switchLanguage}
          />
        </div>

        {/* Mobile Navigation - visible on smaller tablets and phones */}
        <div className="lg:hidden flex justify-between items-center w-full">
          <MobileNav
            isFrench={isFrench}
            getPathPrefix={getPathPrefix}
            toggleMenu={toggleMenu}
            isMenuOpen={isMenuOpen}
            switchLanguage={switchLanguage}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
