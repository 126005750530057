import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import ContactHeroSection from "../sections/ContactHeroSection";
import ContactInfoSection from "../sections/ContactInfoSection";
import ContactMapSection from "../sections/ContactMapSection";
import ContactFormSection from "../sections/ContactFormSection";

const ContactPage = () => {
  const location = useLocation();
  const isFrench = location.pathname.startsWith("/fr");

  return (
    <>
      <Helmet>
        <title>
          {isFrench
            ? "Contactez Northtouch Canada Inc. - Représentant en Fabrication Électronique"
            : "Contact Northtouch Canada Inc. - Electronics Manufacturing Representative"}
        </title>
        <meta
          name="description"
          content={
            isFrench
              ? "Contactez Northtouch Canada Inc. pour des informations sur les solutions de fabrication électronique, les équipements de test et les machines d'assemblage. Nous sommes un représentant de fabricants de premier plan au service de la communauté canadienne de fabrication électronique."
              : "Contact Northtouch Canada Inc. for information on electronics manufacturing solutions, testing equipment, and assembly machines. We are a leading manufacturer's representative serving the Canadian electronics manufacturing community."
          }
        />
      </Helmet>

      <ContactHeroSection isFrench={isFrench} />
      <ContactInfoSection isFrench={isFrench} />
      <ContactMapSection />
      <ContactFormSection isFrench={isFrench} />
    </>
  );
};

export default ContactPage;
