import React from "react";
import { motion } from "framer-motion";

const ContactHeroSection = ({ isFrench }) => {
  return (
    <section className="pt-32 md:pt-40 pb-20 md:pb-24 relative overflow-hidden">
      {/* Background elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-black z-0"></div>

      {/* Blur effects */}
      <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-purple-500 rounded-full filter blur-[120px] opacity-20 mix-blend-screen"></div>
      <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-indigo-500 rounded-full filter blur-[120px] opacity-20 mix-blend-screen"></div>
      <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>

      {/* Floating orbs */}
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute w-64 h-64 bg-purple-400/30 rounded-full blur-3xl animate-float"
          style={{
            top: "15%",
            left: "10%",
            animationDuration: "25s",
            opacity: 0.2,
          }}
        ></div>
        <div
          className="absolute w-80 h-80 bg-indigo-500/20 rounded-full blur-3xl animate-float-slow"
          style={{
            top: "60%",
            left: "75%",
            animationDuration: "35s",
            opacity: 0.15,
          }}
        ></div>
        <div
          className="absolute w-48 h-48 bg-purple-600/20 rounded-full blur-3xl animate-float-reverse"
          style={{
            top: "75%",
            left: "15%",
            animationDuration: "30s",
            opacity: 0.1,
          }}
        ></div>
      </div>

      <div className="container mx-auto px-6 relative z-10">
        <motion.div
          className="max-w-4xl mx-auto text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white mb-6 leading-tight">
            {isFrench ? (
              <>
                Restez en{" "}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
                  Contact
                </span>
              </>
            ) : (
              <>
                Get in{" "}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-indigo-400">
                  Touch
                </span>
              </>
            )}
          </h1>
          <p className="text-lg sm:text-xl text-purple-100 mb-10 max-w-3xl mx-auto">
            {isFrench
              ? "Nous sommes là pour répondre à toutes vos questions concernant nos fabricants ou produits. Contactez-nous et nous vous répondrons dès que possible."
              : "We're here to answer any questions you may have about our manufacturers or products. Reach out to us and we'll respond as soon as we can."}
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactHeroSection;
