import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/northtouch-site-logo.png";

const DesktopNav = ({
  isFrench,
  getPathPrefix,
  currentPath,
  switchLanguage,
}) => {
  return (
    <>
      {/* Logo */}
      <Link
        to={isFrench ? "/fr" : "/"}
        className="flex items-center relative z-10 transition-transform duration-300 hover:scale-105"
      >
        <img src={logo} alt="Northtouch Canada Inc." className="h-10 sm:h-12" />
      </Link>

      {/* Navigation Links */}
      <nav>
        <ul className="flex items-center space-x-10">
          <NavItem
            to={`${getPathPrefix()}/`}
            label={isFrench ? "Accueil" : "Home"}
            currentPath={currentPath}
          />
          <NavItem
            to={`${getPathPrefix()}/manufacturers`}
            label={isFrench ? "Fabricants" : "Manufacturers"}
            currentPath={currentPath}
          />
          <NavItem
            to={`${getPathPrefix()}/products`}
            label={isFrench ? "Produits" : "Products"}
            currentPath={currentPath}
          />
          <NavItem
            to={`${getPathPrefix()}/contact`}
            label={isFrench ? "Contact" : "Contact"}
            currentPath={currentPath}
          />

          <li>
            <button
              onClick={switchLanguage}
              className="flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 hover:text-primary-600 transition-colors rounded-md hover:bg-gray-100"
              aria-label={isFrench ? "Switch to English" : "Switch to French"}
            >
              {isFrench ? "EN" : "FR"}
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          </li>

          <li>
            <Link
              to={`${getPathPrefix()}/contact`}
              className="ml-2 bg-gradient-to-r from-primary-600 to-purple-600 text-white px-6 py-2.5 rounded-full font-medium transition-all duration-300 transform hover:scale-105 hover:shadow-lg flex items-center"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              {isFrench ? "Nous Contacter" : "Get in Touch"}
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

// Desktop Nav Item Component with enhanced underline animation
const NavItem = ({ to, label, currentPath }) => {
  const isActive = currentPath === to;

  return (
    <li>
      <Link
        to={to}
        className={`relative px-3 py-2 text-base font-medium group transition-colors duration-300 flex items-center rounded-md ${
          isActive
            ? "text-primary-700 bg-primary-50/60"
            : "text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        }`}
      >
        {label}
        <span
          className={`absolute -bottom-px left-0 right-0 mx-auto w-0 h-0.5 rounded-full bg-gradient-to-r from-primary-600 to-purple-600 transition-all duration-300 ${
            isActive ? "w-2/3" : "group-hover:w-2/3"
          }`}
        ></span>
      </Link>
    </li>
  );
};

export default DesktopNav;
