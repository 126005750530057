import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

const ContactForm = ({ isFrench }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const [status, setStatus] = useState({
    submitting: false,
    submitted: false,
    error: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (!formData.name || !formData.email || !formData.message) {
      return isFrench
        ? "Veuillez remplir tous les champs obligatoires"
        : "Please fill in all required fields";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      return isFrench
        ? "Veuillez entrer une adresse e-mail valide"
        : "Please enter a valid email address";
    }

    return null;
  };

  const handleSubmit = () => {
    const validationError = validateForm();
    if (validationError) {
      setStatus({
        submitting: false,
        submitted: false,
        error: validationError,
      });
      return;
    }

    setStatus({ submitting: true, submitted: false, error: null });

    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone || "Not provided",
      company: formData.company || "Not provided",
      message: formData.message,
      time: new Date().toLocaleString(),
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams
      )
      .then(
        (response) => {
          setStatus({
            submitting: false,
            submitted: true,
            error: null,
          });
          // Clear form
          setFormData({
            name: "",
            email: "",
            phone: "",
            company: "",
            message: "",
          });
        },
        (error) => {
          setStatus({
            submitting: false,
            submitted: false,
            error: isFrench
              ? "Échec de l'envoi du message. Veuillez réessayer."
              : "Failed to send message. Please try again.",
          });
        }
      );
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {isFrench ? "Nom" : "Name"}*
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent
              ${
                !formData.name && status.error
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {isFrench ? "Courriel" : "Email"}*
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent
              ${
                !formData.email && status.error
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {isFrench ? "Téléphone" : "Phone"}
          </label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {isFrench ? "Entreprise" : "Company"}
          </label>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
          />
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          {isFrench ? "Message" : "Message"}*
        </label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
          className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent
            ${
              !formData.message && status.error
                ? "border-red-500"
                : "border-gray-300"
            }`}
        />
      </div>

      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-500">
          {isFrench ? "* Champs requis" : "* Required fields"}
        </p>
        <button
          onClick={handleSubmit}
          disabled={status.submitting}
          className={`px-6 py-3 bg-primary-600 text-white rounded-lg font-medium hover:bg-primary-700 transition-colors ${
            status.submitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {status.submitting
            ? isFrench
              ? "Envoi en cours..."
              : "Sending..."
            : isFrench
            ? "Envoyer le Message"
            : "Send Message"}
        </button>
      </div>

      {status.submitted && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-4 bg-green-50 text-green-700 rounded-lg"
        >
          {isFrench
            ? "Message envoyé avec succès! Nous vous contacterons bientôt."
            : "Message sent successfully! We will contact you soon."}
        </motion.div>
      )}

      {status.error && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="p-4 bg-red-50 text-red-700 rounded-lg"
        >
          {status.error}
        </motion.div>
      )}
    </div>
  );
};

export default ContactForm;
